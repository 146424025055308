import * as React from "react"
import { Head } from "../../components/head"
import { Layout } from "../../components/layout"
import SEO from "../../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ExpansionPanel } from "../../components/_packages/expansionPanel"
import { ExpandedPanel } from "../../components/_packages/expandedPanel"
import { digitalMarketing } from "../../content/packageContent"
import BackgroundImage from "gatsby-background-image"
import {
  RelatedPackages,
  Package,
} from "../../components/_packages/relatedPackages"
import { SectionContainer } from "../../components/shared/containers"
import { Header } from "../../components/shared/type"

const DigitalMarketingAndSeoPage = (): JSX.Element => {
  const { seoImg } = useStaticQuery(
    graphql`
      query {
        seoImg: file(relativePath: { eq: "services/marketingSEO.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const seoFluid = seoImg.childImageSharp.fluid

  return (
    <>
      <SEO title="Digital Marketing & SEO" />
      <Head title={"Digital Marketing & SEO"} />
      <Layout>
      <SectionContainer style={{paddingBottom: "2rem"}}>
        <PackageHeader>
          <FluidImg fluid={seoFluid} style={{ backgroundSize: "contain" }} />
            <div>
              <Header>Digital Marketing & SEO</Header>
              <Description>{digitalMarketing.description}</Description>
            </div>
        </PackageHeader>
      </SectionContainer>

          <PanelsSmall>
            <ExpansionPanel
              plan={digitalMarketing.plans.email}
              package="Digital Marketing & SEO"
            />
            <ExpansionPanel
              plan={digitalMarketing.plans.seo}
              package="Digital Marketing & SEO"
            />
            <ExpansionPanel
              plan={digitalMarketing.plans.adwords}
              expanded={true}
              package="Digital Marketing & SEO"
            />
          </PanelsSmall>

          <Content>
            <ExpandedPanel
              plan={digitalMarketing.plans.email}
              package="Digital Marketing & SEO"
            />
            <ExpandedPanel
              plan={digitalMarketing.plans.seo}
              package="Digital Marketing & SEO"
            />
            <ExpandedPanel
              plan={digitalMarketing.plans.adwords}
              package="Digital Marketing & SEO"
            />
          </Content>
          <RelatedPackages currentPackage={Package.SEO} />

      </Layout>
    </>
  )
}

const Description = styled.p`
  @media (min-width: 768px) {
  }
`

 const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  height:100%;
  padding-left: 50px;
  padding-right: 50px;
`

const FluidImg = styled(BackgroundImage)`
  height: 200px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    height: 300px;
    width: 400px;
    justify-self: end;
  }
`

const PackageHeader = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    grid-column-gap: 75px;
  }
`

const PanelsSmall = styled.div`
  :nth-of-type(3) {
    background-color: var(--grey);
  }

  :nth-of-type(3) > div {
    background-color: var(--grey);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export default DigitalMarketingAndSeoPage
